@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.LandingContent {
  position: relative;
  text-align: center;
  margin-top: 12%;
  padding: 30px;
  z-index: 4;

  .MaverikLogo {
    opacity: 0;
    animation: fadeIn 1s forwards 0.3s;
  }

  .content-inner {
    max-width: 540px;
    margin: 0px auto 10px auto;
    opacity: 0;
    animation: fadeIn 1s forwards 1.8s;
  }

  .title {
    position: relative;
    display: inline-block;

    h1 {
      text-transform: uppercase;
      letter-spacing: 10px;
      z-index: 2;
      padding-left: 10px;
      padding-right: 10px;            
      opacity: 0;
      animation: fadeInScewX 0.6s forwards 1.5s; 
      animation-timing-function: linear; 
      margin-top: 2px;              

      @include breakpoint(sm) {
        letter-spacing: 12px;
      }

      @include breakpoint(md) {
        letter-spacing: 16px;
      }

      @include breakpoint(lg) {
        letter-spacing: 20px;
      }
    }

    .line {
      background-color: $brand-color;
      position: absolute;
      left: 0;
      height: 10px;
      bottom: 3px;
      z-index: -1;
      width: 100%;
      transform: scale3d(0, 1, 1);
      animation: MoveScaleLeft 0.5s forwards 1s;

      @include breakpoint(sm) {
        height: 16px;
        bottom: 15px;
      }

      @include breakpoint(md) {
        bottom: 24px;
      }

      @include breakpoint(md) {
        bottom: 15px;
      }
    }
  }

  h5 {
    letter-spacing: 3.5px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      letter-spacing: 10px;
    }
  }

  p {
    margin-bottom: 5%;
  }
}

@keyframes MoveScaleLeft {
  from {
    transform: scale3d(0, 1, 1) skewX(0);
    opacity: 0;
  }

  to {
    transform: scale3d(1, 1, 1) skewX(-30deg);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInScewX {
  from {
    opacity: 0;
    margin-top: 2px;        
    transform: translateZ(0) skewX(-9deg);
  }

  to {
    opacity: 1;    
    margin-top: 2px;    
    transform: translateZ(0) skewX(0.05deg);
  }
}
