@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.MaverikLogo {
  svg {
    width: 170px;

    @include breakpoint(sm) {
      width: 220px;
    }
  }
}
