// Back Button Component
.BackButton {
  position: absolute;
  bottom: 18px;
  left: 40px;
  z-index: 99;
  cursor: pointer;
  padding-left: 40px;
  padding-right: 30px;
  padding-bottom: 30px;
  font-weight: 800;
  font-size: 10px;
  text-transform: uppercase;
  opacity: 1;
  transition: 0.4s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    transform: translateY(-9px);
    transition: width 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      background-position-x 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    border-radius: 20px;
    border: 1px solid #5e758c;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 4 8'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' stroke-linecap='round' stroke-width='1' d='M3.601 1 0 4l3.601 3'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 4px;
    background-position-y: center;
    background-position-x: 12px;
  }

  &:hover:not(.disabled) {
    &::before {
      width: 120px;
      background-position-x: 18px;
    }
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}
