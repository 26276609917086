@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 9;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;

  &.landing-page {
    @include breakpoint(xs) {
      justify-content: center;
    }
  }
}

// This footer will be shown on results page
.Footer-Large {
  position: relative;
  color: $light;
  width: 100%;
  padding: 35px 0;
  background-image: radial-gradient(
    circle at 50% -40%,
    #0e3953 10%,
    #07334d 80%
  );

  @include breakpoint(xs) {
    padding: 60px 0 100px;
  }

  .Modal {
    color: $text-color;

    h2 {
      text-transform: uppercase;
      letter-spacing: 4.55px;

      span {
        position: relative;
        &::after {
          content: "";
          background-color: $brand-color;
          position: absolute;
          left: -4px;
          height: 10px;
          bottom: 6px;
          z-index: -1;
          width: 100%;
          transform: skewX(-30deg);
          // transition: width 0.8s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
      }
    }
  }

  .background-shape {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 60%;
    height: 100%;
    background: rgba(1, 32, 51, 1);
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0 100%);
    z-index: 1;

    @include breakpoint(md) {
      display: block;
    }
  }

  .row {
    position: relative;
    z-index: 2;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    @include breakpoint(sm) {
      flex-direction: row;
      min-height: 330px;
    }

    @include breakpoint(md) {
      min-height: 460px;
    }

    .column {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include breakpoint(xs) {
        margin-bottom: 45px;
      }

      h4 {
        letter-spacing: 4px;
        text-align: center;
      }

      .button--default {
        max-width: max-content;
        span {
          color: $light;
        }
      }
    }
  }

  .copyright {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .copyright-text {
      font-size: 12px;
      color: #92a4b5;
      letter-spacing: 0.2px;
    }
  }
}
