@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

// Product Found Component Section
.ProductFound {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding-bottom: 100px;

  @include breakpoint(xs) {
    min-height: auto;
  }

  .MaverikLogo {
    position: absolute;
    top: 30px;
  }

  .article {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 1150px;

    @include breakpoint(sm) {
      flex-direction: row;
    }

    h6 {
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 15px;
    }

    .product-preview-image {
      position: relative;
      padding-left: 15px;
      padding-right: 15px;
      aspect-ratio: 9/16;
      max-width: 325px;
      max-height: 100%;

      @include breakpoint(xs) {
        height: 430px;
      }

      @include breakpoint(sm) {
        margin-right: 26px;
        flex-basis: 170%;
        padding: 20px;
      }

      @include breakpoint(md) {
        margin-right: 0;        
        flex-basis: 100%;
        aspect-ratio: 8/12;
        min-width: 400px;
        max-height: 100%;
      }

      @include breakpoint(lg) {
        // margin-left: 70px;
        min-width: 440px;
        margin-right: 35px;
        padding: 0;        
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 6px;
        width: 220px;
        border-radius: 50%;
        background-color: #dedede;
        margin: auto;
        filter: blur(2px);

        @include breakpoint(xs) {
          width: 180;
        }
      }

      img {
        display: block;
        margin: auto;
        max-width: 100%;
        height: 100%;
        object-fit: contain;
        // animation: moveMainPreview 6s linear infinite alternate;

        @include breakpoint(xs) {
          max-width: 80%;
        }
      }

      .download-share {
        position: absolute;
        width: 67px;
        right: 20px;
        bottom: 50px;
        text-align: center;
        cursor: pointer;

        @include breakpoint(sm) {
          bottom: -60px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
          
        @include breakpoint(md) {
          left: inherit;
          right: 50px;
          bottom: 50px;
        }

        .svg-label {
          display: block;
          font-weight: 800;
          text-transform: uppercase;
          font-size: 11px;
        }

        .download-share-icon {
          width: 14px;
        }
      }

      .MadeInUSA {
        position: absolute;
        left: -20px;
        top: 10%;
        z-index: -1;

        @include breakpoint(sm) {
          left: 8px;
          top: -50px;
          width: 85px;
        }

        @include breakpoint(md) {
          letter-spacing: -20px;
          top: 12%;
          left: 10%;
        }

        @include breakpoint(xs) {
          width: 70px;
        }
      }
    }

    .product-description {
      max-width: 660px;

      .logo {
        max-width: 375px;
        margin-bottom: 30px;
      }

      .product-optimized-for {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column-reverse;
        border-bottom: 1px solid $mid-grey;

        @include breakpoint(sm) {
          flex-direction: row;
          height: 130px;
        }

        h5 {
          font-size: 13px;
          letter-spacing: 2px;
          padding-right: 42px;
          text-transform: uppercase;
          min-width: 30%;

          @include breakpoint(xs) {
            display: block;
          }

          @include breakpoint(sm) {
            border-right: 1px solid $mid-grey;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      img {
        height: 100%;
        width: auto;
        max-width: 100%;
      }

      .paragraph {
        border-top: 1px solid $mid-grey;
        border-bottom: 1px solid $mid-grey;
        padding: 20px 0;

        @include breakpoint(md) {
          padding-right: 55px;
        }

        p {
          margin: 0;
          line-height: 1.25rem;
        }
      }
    }

    .bottom-content {
      min-height: 80px;
      font-size: 25px;
      border-bottom: 1px solid $mid-grey;
      display: flex;
      align-items: center;      
      justify-content: center;
      flex-direction: column;
      padding: 10px 0;

      @include breakpoint(sm) {
        min-height: 100px;
      }

      @include breakpoint(md) {
        min-height: 120px;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 40px;
      }

      &.with-retailers {
        .buy-buttons {
          margin-left: 0;
          width: 100%;

          @include breakpoint(md) {
            margin-left: auto;
            width: auto;
          }

          .retailer-buy-buttons {            
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            @include breakpoint(md) {
              flex-direction: row;
            }            
          }
        }

        @include breakpoint(md) {
          padding-right: 0px;
        }

        .button--select {
          @include breakpoint(md) {
            margin-left: auto;            
          }
        }
      }

      &.with-cascade-buy-button {                        
        padding-right: 0;
        
        .buy-buttons {
          margin-left: 0;
          width: 100%;

          @include breakpoint(md) {
            margin-left: auto;
            width: auto;
          }
  
          .cascade-buy-button {
            margin-top: 0.75rem;
  
            .button--buy {
              margin-left: 0;
              width: 100%;
              font-size: 1rem;
            }
          }
  
          .buy-button-divider {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.5rem 0;

            @include breakpoint(md) {
              margin: 0.5rem 0 1rem;
            }
  
            .buy-button-divider--bar {
              background: #CDCDCD;
              display: block;
              height: 1px;
              width: 100%;
              margin: 0 0.5rem;
            }
  
            .buy-button-divider--text {            
              font-size: 1rem;
            }
          }
  
          .retailer-buy-buttons {
            margin-bottom: 0.75rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      &.with-retailers.with-cascade-buy-button {
        align-items: center;
        border-bottom: none;
        
        @include breakpoint(md) {
          align-items: flex-start;
        }

        .price {
          padding-top: 0.8rem;
        }
      }

      .button {
        margin-top: 15px;
        width: 100%;        

        @include breakpoint_max(768px) {
          min-width: 100%;
        }

        @include breakpoint(md) {
          margin-top: 0;
        }
      }

      .price {        
        font-weight: 800;

        @include breakpoint(sm) {
          font-size: 40px;        
        }

        sub {
          font-size: 16px;
          font-weight: 400;
        }
      }      
    }
  }

  .scroll-indicator {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    width: 33px;
    height: 33px;

    @include breakpoint(xs) {
      display: none;
    }

    a {
      position: initial;
      display: inherit;
      width: 100%;
      height: 100%;
    }

    .scroll-text {
      position: absolute;
      top: -15px;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 10px;
    }

    .vertical-line {
      width: 1px;
      height: 33px;
      background-color: $brand-color;
      display: inline-block;
      left: 0;
      right: 0;
      margin: auto;
      position: absolute;
      top: -50%;
      transform-origin: bottom;
      animation: scrollAnim 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
        both;
    }
  }
}

@keyframes moveMainPreview {
  from {
    transform: translateY(0) translateX(0);
  }
  to {
    transform: translateY(15px) translateX(3px);
  }
}

@keyframes moveShadow {
  0% {
    background-color: #dedede;
  }
  25% {
    background-color: #d4d4d4;
  }
  50% {
    background-color: #c2c2c2;
  }
  75% {
    background-color: #b8b8b8;
  }
  100% {
    background-color: #949494;
  }
}