@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.QuestionsList {
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  height: calc(100% - 32px);
  top: 32px;
  transition: opacity 0.4s ease-in-out;
  z-index: 9;

  ul {
    position: relative;
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      margin-bottom: 20px;
      opacity: 0;

      @for $i from 1 to 10 {
        &:nth-child(#{$i}) {
          animation-delay: $i * 0.08s;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -8vh;
      left: 0;
      right: 0;
      margin: auto;
      width: 80%;
      height: 7px;
      border-radius: 50%;
      background: #041019;
      filter: blur(4px);
      // animation: blurMove 10s cubic-bezier(0.215, 0.61, 0.355, 1) alternate
      //   infinite;
    }
  }

  h2 {
    position: relative;
    text-transform: uppercase;
    font-weight: 900;
    padding: 5px 20px;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 18px;
    opacity: 0;
    transition: opacity 0.8s ease-in;
    letter-spacing: 4.55px;

    @include breakpoint(xs) {
      letter-spacing: 2.27px;
    }

    .highlight {
      position: relative;
      z-index: 1;

      &::after {
        content: "";
        background-color: $brand-color;
        position: absolute;
        left: 0;
        height: 8px;
        bottom: 3px;
        z-index: -1;
        width: 0;
        transform: skewX(-30deg);
        transition: width 0.8s cubic-bezier(0.55, 0.055, 0.675, 0.19);

        @include breakpoint(sm){
          height: 11px;
          bottom: 4px;
        }

        @include breakpoint(md){
          bottom: 7px;
        }
        @include breakpoint(lg){
          bottom: 7px;
        }
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    h2 {
      opacity: 1;

      .highlight {
        &::after {
          width: 100%;
        }
      }
    }

    ul {
      li {
        animation-name: MoveScaleUp;
        animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        animation-duration: 0.6s;
        animation-direction: alternate;
        animation-fill-mode: forwards;
      }
    }
  }

  .button {
    &--default {
      span {
        color: #fff;
      }
    }
  }
}

@keyframes MoveScaleUp {
  from {
    transform: translate3d(0, 10px, 0) scale3d(1, 1, 1);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes blurMove {
  from {
    opacity: 0.8;
    transform: translateY(10px);
    width: 80%;
  }

  to {
    transform: translateY(0);
    opacity: 1;
    width: 40%;
  }
}
