@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

model-viewer {
  &.model-view {
    opacity: 0;
    height: 100%;
    width: 100%;
    --poster-color: transparent;
    transition: opacity 1.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  // default loading from model-viewer
  .loading {
    display: none;
  }
}

.poster-image {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: 59.9%;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 1.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 200px;
  height: 8px;
  margin: auto;
  text-align: center;
  transform: skewX(-30deg);
  overflow: hidden;
  
  .progress-inner {
    height: 100%;
    width: 0;
    background-color: $brand-color;
    transition: width 1s cubic-bezier(0.165, 0.84, 0.44, 1),
      transform 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
    transform: translateX(0);
    transform-origin: 0% 50%;

    &.loaded {
      transform: translateX(100%);
      transform-origin: 100% 50%;
    }
  }
}
