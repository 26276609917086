@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.ProductRecommended {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: hidden;
  max-width: 100vw;
  overflow: hidden;
  background-image: radial-gradient(
    circle at 50% 177%,
    #ffffff 50%,
    rgba(255, 255, 255, 0.01) 100%,
    rgba(255, 255, 255, 0) 100%,
    rgba(255, 255, 255, 0) 100%
  );

  &.no-recommendation {
    display: none;
  }

  @include breakpoint(sm) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h4 {
    letter-spacing: 6.25px;
    text-transform: uppercase;
  }

  .products-holder {
    position: relative;
    z-index: 9;
    transition: opacity .4s ease-in;

    @include breakpoint(sm) {
      display: flex;
      max-width: 1100px;
      width: 100%;
      align-content: center;
      justify-content: space-between;
    }

    .product {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 50px;

      @include breakpoint(sm) {
        max-width: 300px;
      }

      @include breakpoint(md) {
        display: flex;
        max-width: 100%;
      }

      .content {
        display: flex;        
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 100%;

        @include breakpoint(xs) {
          max-width: 280px;
        }

        @include breakpoint(md) {
          display: flex;
          max-width: 240px;
        }

        .logo {
          margin-bottom: 20px;

          img {
            max-width: 216px;
            width: 100%;
          }
        }

        .button-holder {
          margin-top: 20px;

          .button {
            min-width: 210px;
          }
        }
      }

      .image {
        position: relative;
        display: flex;
        justify-content: center;
        height: 270px;
        max-width: 280px;
        padding-bottom: 30px;

        @include breakpoint(md) {
          height: 400px;
        }

        img {
          min-height: 100%;
          width: 100%;
          object-fit: contain;

          @include breakpoint(xs) {
            display: block;
            margin: auto;
            max-height: 200px;
            margin-bottom: 20px;
          }

          @include breakpoint(md) {
            padding-left: 30px;
            padding-right: 30px;
          }
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 14px;
          left: 0;
          right: 0;
          height: 6px;
          width: 120px;
          border-radius: 50%;
          background-color: #dedede;
          margin: auto;
          filter: blur(2px);

          @include breakpoint(sm) {
            width: 140px;
          }

          @include breakpoint(md) {
            width: 170px;
            bottom: 0;
          }
        }
      }

      .button-holder {
        display: flex;
        flex-direction: column;
      }
    }
  }
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
