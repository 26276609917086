@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.LandingPage {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $light-grey;

  @include breakpoint(xs) {
    max-height: -webkit-fill-available;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: moveBg 6s linear infinite alternate;
    background-image: url("../../../assets/images/landing_page_bg.webp");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 155%;
    z-index: 2;

    @include breakpoint(sm) {
      background-position: center bottom;
      background-size: 100%;
    }

    @include breakpoint(md) {
      background-position: center top;
      background-size: 90%;
    }

    @include breakpoint(lg) {
      background-position: center top;
      background-size: 63%;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: radial-gradient(
      circle at 50% 177%,
      #f7f7f7 50%,
      rgba(255, 255, 255, 0.01) 100%,
      rgba(255, 255, 255, 0) 100%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 0;
  }
}

@keyframes moveBg {
  from {
    transform: translateY(0) translateX(0);
  }
  to {
    transform: translateY(4%) translateX(1%);
  }
}
