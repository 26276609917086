@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.cursor {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99999;
  width: 16px;
  height: 16px;
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: transform;
  transition-property: transform;
  pointer-events: none;

  @include breakpoint_max(991px) {
    display: none;
  }

  .circle {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $brand-color;
    border-radius: 50%;
    pointer-events: none;
    width: 100%;
    height: 100%;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center center;
    transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
  }

  &--link-hovered {
    .circle {
      width: 5px;
      height: 5px;
      background-color: #fff;
    }
  }

  &--clicked {
    .circle {
      animation: pulse 0.6s ease-in-out;
    }
  }

  &--hidden {
    .circle {
      opacity: 0;
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 transparentize($brand-color, 0.4);
  }

  70% {
    box-shadow: 0 0 0 60px transparentize($brand-color, 1);
  }

  100% {
    box-shadow: 0 0 0 0 transparentize($brand-color, 1);
  }
}
