// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-ExtraBold.otf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-UltraLightItalic.otf");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-BoldItalic.otf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-SemiBold.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-BlackItalic.otf");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-ThinItalic.otf");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-ExtraBoldItalic.otf");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Black.otf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-HeavyItalic.otf");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-SemiBoldItalic.otf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Bold.otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-RegularItalic.otf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Light.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-LightItalic.otf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Heavy.otf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-MediumItalic.otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Thin.otf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Redzone";
  src:  url("../../assets/fonts/Redzone/Redzone-BlackReg.woff") format("woff"), 
        url("../../assets/fonts/Redzone/Redzone-BlackReg.ttf") format("truetype"),
        url("../../assets/fonts/Redzone/Redzone-BlackReg.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
