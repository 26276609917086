@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.ProgressBar {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 100%;
  background-color: $light-grey;
  z-index: 9;
  overflow-x: hidden;

  .progress-line {
    position: absolute;
    left: 0;
    width: 0;
    height: 17px;
    background-color: $brand-color;
    transition: width 0.8s ease-in-out 0.2s;

    &::after {
      content: "";
      width: 20px;
      height: 17px;
      transform: skewX(-30deg);
      background-color: $brand-color;
      position: absolute;
      right: -10px;
    }

    span {
      position: absolute;
      right: 5px;
      z-index: 9;
      font-size: 10px;
      height: 18px;
      padding-top: 4px;
      font-weight: 900;
    }
  }
}
