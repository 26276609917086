.MaverikLogoQuestionnare {
    position: absolute;
    top: 32px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    z-index: 9;

    h6 {
        margin: 0;
        margin-top: 10px;
        letter-spacing: 2px;
    }

    svg {
        width: 45px;
        height: 46px;
    }
}
