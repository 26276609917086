@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.CostumForm {
  max-width: 445px;
  margin: auto;

  .input-holder {
    position: relative;
    margin-bottom: 30px;

    .check {
      opacity: 0.8;
      transition: opacity 0.4s ease-in;
      position: absolute;
      right: 15px;
      bottom: 13px;
      display: inline-block;
      transform: rotate(45deg);
      height: 18px;
      width: 9px;
      border-bottom: 3px solid #dadada;
      border-right: 3px solid #dadada;

      &.valid {
        opacity: 1;
        border-bottom: 3px solid #78b13f;
        border-right: 3px solid #78b13f;
      }
    }
  }

  .status {
    padding: 25px;
    margin-bottom: 40px;
    display: block;
    background: #f6f6f6;
    border: 1px solid #e2e2e2;
    font-weight: 600;

    &.success {
      color: #12be69;
    }
    &.error {
      color: $brand-color;
    }

    &.sending {
      color: #12be69;
    }
  }

  .is-required {
    display: block;
    margin-top: 15px;

    span {
      color: $brand-color;
    }
  }

  input[type="text"],
  input[type="email"] {
    border: 0;
    border-bottom: 2px solid #a2a2a2;
    width: 100%;
    height: 50px;
    font-family: $text-font-stack;
    font-size: 16px;
    font-weight: 800;
    outline: 0;
  }

  input[type="text"]:focus,
  input[type="email"]:focus {
    border-bottom: 2px solid $text-color;
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder {
    opacity: 0;
  }

  input[type="text"]:focus + label,
  input[type="email"]:focus + label,
  input[type="text"]:not(:placeholder-shown) + label,
  input[type="email"]:not(:placeholder-shown) + label {
    transform: translateY(-28px);
    font-size: 12px;
    font-weight: 600;
    color: $text-color;
  }

  label {
    position: absolute;
    left: 5px;
    bottom: 13px;
    transition: transform 0.2s linear, font-weight 0.2s linear,
      font-size 0.2s linear, color 0.4s ease-in;
    transform: translateY(0);
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    color: #a2a2a2;

    span {
      color: $brand-color;
    }
  }
}
