// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  height: -webkit-fill-available;
  scroll-behavior: smooth;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/**
 * Basic styles for links
 */
a {
  color: $text-color;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  @include on-event {
    text-decoration: none;
  }

  &.has-underline {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: currentColor;
      top: 100%;
      left: 0;
      pointer-events: none;
    }

    &::before {
      transform-origin: 100% 50%;
      transform: scale3d(0, 1, 1);
      transition: transform 0.3s;
    }

    &:hover::before {
      transform-origin: 0% 50%;
      transform: scale3d(1, 1, 1);
    }
  }
}

hr {
  border: 0;
  width: 100%;
  height: 1px;
  transform: scaleX(-1);
  background-image: linear-gradient(
    270deg,
    #898989 0%,
    rgba(255, 255, 255, 0) 100%
  );
  margin: 20px 0;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}