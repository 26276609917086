//This will be reuseble on seperate componet

.button {
  user-select: none;
  outline: 0;
  border: 2px solid $text-color;
  text-transform: uppercase;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
  padding: 12px 40px;
  min-width: 330px;
  pointer-events: auto;
  cursor: pointer;
  position: relative;
  display: inline-block;
  background: none;
  text-align: center;
  font-family: $text-font-stack;

  &:focus,
  &:focus-within {
    outline: transparent;
  }

  @include breakpoint(sm) {
    padding: 15px 40px;
  }

  &--default {
    border: 2px solid transparent;    
    z-index: 2;
    box-shadow: inset 0 0 0 2px $brand-color;    

    &::before,
    &::after {
      position: absolute;
      width: calc(100% + 80px);
      top: -18px;
      left: -40px;
      height: calc(100% + 36px);
      overflow: hidden;
      z-index: 1;
    }

    span {
      font-size: 14px;
      display: block;
      position: relative;
      z-index: 10;
      color: $text-color;
      font-weight: 800;
      letter-spacing: 0.4px;
      transition: color cubic-bezier(0.1, 0, 0.3, 1);

      @include breakpoint(sm) {
        font-size: 16px;
        letter-spacing: 0.6px;
      }
    }

    &:hover span {
      color: $light;
      animation: ScaleUpInit 0.1s forwards, MoveScaleUp 0.1s forwards 0.3s;
    }

    &::before {
      content: "";
      background: $brand-color;
      border-radius: 30px;
      transform: translate3d(0, 70%, 0) scale3d(0, 0, 0);
    }

    &:hover::before {
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      transition: transform 0.5s cubic-bezier(0.1, 0, 0.3, 1);
    }

    &::after {
      content: "";
      background: $brand-color;
      transform: translate3d(0, -105%, 0);
      transition: transform 0.5s cubic-bezier(0.1, 0, 0.3, 1);
    }

    &:hover::after {
      transform: translate3d(0, 0, 0);
      transition-duration: 0.05s;
      transition-delay: 0.3s;
      transition-timing-function: cubic-bezier(0.1, 0, 0.3, 1);
    }
    
  }

  &--select {
    padding: 0;
    font-weight: 800;
    transition: background-color 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    text-align: left;
    color: $text-color;
  
    select {
      cursor: pointer;
      position: relative;
      z-index: 8;      
      outline: none;
      -webkit-appearance: none;
      padding: 12px 40px;
      font-family: $text-font-stack;
      background: none;
      border: none;
      font-size: 16px;
      font-weight: 800;
      color: $text-color;
      text-transform: uppercase;
      width: 100%;

      @include breakpoint(sm) {
        padding: 13px 45px 13px 28px;
        text-overflow: ellipsis;
        max-width: 260px;
      }
    }

    @include breakpoint (md) {
      min-width: 257px;
    }

    @include breakpoint(sm) {
      font-size: 13px;
    }

    &::after {
      content: "";
      position: absolute;
      right: 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 9'%3E%3Cpath fill='none' stroke='%23424B4E' stroke-linecap='round' stroke-width='2' d='m1.161 1.839 6.1395 5.2021 6.1396-5.2021'/%3E%3C/svg%3E");
      width: 26px;
      height: 13px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      top: calc(50% - 7px);
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
    }

    @include on-event {
      &::after {
        animation: ScaleUpInit 0.3s forwards, MoveScaleUp 0.3s forwards 0.3s;
      }
    }
  }

  &--buy {
    font-weight: 800;
    transition: background-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    color: $light;
    background-color: $brand-color;
    border: 2px solid $brand-color;

    @include breakpoint(sm) {
      font-size: 13px;
    }

    @include breakpoint(md) {
      min-width: 120px;
      margin-left: 10px;
    }

    @include breakpoint (lg) {
      min-width: 200px;
    }

    @include on-event {
      background-color: $brand-color;
      border-color: $brand-color;
      span {
        display: inline-block;
      }
    }

    &.button--disabled {
      pointer-events: none;
      background-color: $dark-grey;
      border: 2px solid $dark-grey;
    }
  }
}

@keyframes ScaleUpInit {
  to {
    transform: translate3d(0, -110%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }
}

@keyframes MoveScaleUp {
  from {
    transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
