@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.Modal,
.modal-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.Modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 99;


  .modal-overlay {
    position: absolute;
    z-index: 1;
    background: rgba(0,14,22,0.7);;
    opacity: 0;
    transition: opacity 0.3s;
    backface-visibility: hidden;
  }

  &.modal-open {

    .modal-overlay {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }

    .modal-content {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }

    .close-icon {
      visibility: visible;
      opacity: 1;
    }
  }

  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15'%3E%3Cpath fill='%23424B4E' d='M14.6544 13.864 8.255 7.4647l6.364-6.364-.6365-.6364-6.364 6.364L1.2195.429l-.7071.707 6.3993 6.3994-6.364 6.364.6364.6363 6.364-6.364 6.3993 6.3994z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .modal-content {
    width: 90%;
    max-width: 600px;
    min-height: 520px;
    background: $light;
    padding: 30px;
    text-align: center;
    position: relative;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
  }
}

.Modal {
  &.modal-open .modal-content,
  &.modal-close .modal-content {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &.modal-open .modal-content {
    animation-name: OpenAnim;
  }

  &.modal-close .modal-content {
    animation-name: closeAnim;
  }
}

@keyframes OpenAnim {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1);
  }

  100% {
    visibility: visible;
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}


@keyframes closeAnim {
  0% {
    visibility: visible;
    opacity: 1;
  }

  100% {
    visibility: hidden;
    opacity: 0;
    transform: scale3d(0.9, 0.9, 1);
  }
}