@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.QuestionnarePage {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow-y: auto;

  @include breakpoint(xs) {
    max-height: -webkit-fill-available;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at 50% -40%, #014063 10%, #0a1823 70%);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  // Texture image should be exported transparent 
  // &::after {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   background-image: url("../../../assets/images/questionnare_texture.png");
  //   background-size: cover;
  //   opacity: .2;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   z-index: 1;
  // }
}


// Temporary visual ID debug
.temp-dev {
  position: fixed;
  font-size: 10px;
  top: 40px;
  left: 10px;
  font-size: 8px;
}
