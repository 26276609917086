// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }
 
  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }
 
  @else if $class == md {
    @media (min-width: 992px) { @content; }
  }
 
  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }
 
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

@mixin breakpoint_min($constum-min) {
  @media (min-width: $constum-min ) {
    @content;
  }
}

@mixin breakpoint_max($constum-max) {
  @media screen and  (max-width: $constum-max ) {
    @content;
  }
}