@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.WasPlayed {
  position: relative;
  height: 168px;
  width: 100%;

  @include breakpoint(sm) {
    height: 311px;
  }

  @include breakpoint(md) {
    height: 460px;
  }

  .image-thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-size: cover;
  }

  .content-shape {
    position: absolute;
    right: 0;
    width: 64%;
    height: 100%;
    z-index: 1;
    background-image: radial-gradient(
      circle at 50% -40%,
      #014063 10%,
      #0a1823 70%
    );
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0 100%);
  }

  .content {
    position: absolute;
    left: 40%;
    height: 100%;
    z-index: 1;
    color: $light;

    @include breakpoint(xs) {
      left: 47%;
    }

    .inner-text {
      position: relative;
      z-index: 3;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .used-by {
        font-size: 14px;
        font-weight: 900;
        font-style: italic;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-style: italic;
        padding-left: 5px;
        display: inline-block;

        @include breakpoint(sm) {
          padding-left: 10px;
          font-size: 16px;
        }

        @include breakpoint(md) {
          padding-left: 20px;
        }

        @include breakpoint(lg) {
          padding-left: 30px;
          letter-spacing: 5px;
        }
      }

      .player-name {
        position: relative;
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 800;
        font-family: "Redzone";
        line-height: normal;
        margin-bottom: 5px;

        @include breakpoint(sm) {
          font-size: 50px;
          margin-top: -7px;
          margin-bottom: 7px;
        }

        @include breakpoint(md) {
          font-size: 70px;          
          margin-top: -12px;
          margin-bottom: 7px;
        }

        @include breakpoint(lg) {
          font-size: 100px;
          font-size: clamp(16px, 6vw, 100px);           
          margin-top: -19px;
          margin-bottom: 9px;         
        }
      
        &::after {
          content: "";
          position: absolute;
          left: -5px;
          bottom: 2px;
          height: 9px;
          width: calc(100% + 10px);
          background-color: $brand-color;
          transform: skew(-30deg);
          z-index: -1;

          @include breakpoint(sm) {
            height: 15px;
            bottom: 4px;
          }

          @include breakpoint(md) {
            height: 16px;
            bottom: 8px;
          }

          @include breakpoint(lg) {
            height: 18px;
            bottom: 8px;
          }
        }
      }

      .team {
        align-self: flex-end;
        font-style: italic;
      }
    }
  }
}
