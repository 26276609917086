/// Overlay Component
.Overlay {
  position: fixed;
  z-index: 1000;
  left: 0;
  bottom: 0;
  pointer-events: none;
  width: 100vw;
  height: 100vh;

  &.animate {
    path {
      animation: AnimateOverlay 1s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
    }
  }
}

@keyframes AnimateOverlay {
  0% {
    d: path("M 0 0 V 100 Q 50 100 100 100 V 0 z");
  }

  50% {
    d: path("M 0 0 V 100 Q 50 100 100 100 V 99 z");
  }

  100% {
    d: path("M 4 98 V 100 Q 50 100 100 100 V 99 z");
  }
}


@keyframes AnimateOverlayCloseXY {
  0% {
    d: path("M 0 100 V 0 Q 50 0 100 0 V 100 z");
  }

  50% {
    d: path("M 0 100 V 50 Q 50 100 100 50 V 100 z");
  }

  100% {
    d: path("M 0 100 V 100 Q 50 100 100 100 V 100 z");
  }
}
