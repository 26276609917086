/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font-size: 16px;
  font-family: $text-font-stack;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include breakpoint(sm) {
    font-size: 14px;
  }

  @include breakpoint(md) {
    font-size: 16px;
  }
}

h1 {
  font-size: 35px;
  font-weight: 900;
  margin: 0;

  @include breakpoint(sm) {
    font-size: 88px;
  }

  @include breakpoint(md) {
    font-size: 100px;
  }
}

h2 {
  font-size: 25px;
  font-weight: 900;

  @include breakpoint(sm) {
    font-size: 40px;
  }

  @include breakpoint(md) {
    font-size: 50px;
  }
}

h3 {
  font-size: 20px;
  font-weight: bold;

  @include breakpoint(sm) {
    font-size: 30px;
  }

  @include breakpoint(md) {
    font-size: 40px;
  }
}

h4 {
  font-size: 14px;
  font-weight: 900;

  @include breakpoint(sm) {
    font-size: 20px;
  }
  @include breakpoint(md) {
    font-size: 25px;
  }
}

h5 {
  font-size: 14px;
  font-weight: 800;

  @include breakpoint(sm) {
    font-size: 16px;
  }
}

h6 {
  font-size: 13px;
  font-weight: 800;

  @include breakpoint(sm) {
    font-size: 16px;
  }
}
