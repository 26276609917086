/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
      outline: none;
  }

  &.dragging {
      cursor: pointer;
      cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
      content: "";
      display: table;
  }

  &:after {
      clear: both;
  }

  .slick-loading & {
      visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
      float: right;
  }
  img {
      display: block;
  }
  &.slick-loading img {
      //display: none;
      background: red;

  }

  &.dragging img {
      pointer-events: none;
  }

  .slick-initialized & {
      display: block;
  }

  .slick-loading & {
      visibility: hidden;
  }

  .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

//Costum Arrow for slick slider

.slick-arrow {

  @include breakpoint(xs) {
    width: auto;
  }

  position: absolute;
  top: 0;
  z-index: 9;
  background: none;
  width: 88px;
  height: 100%;
  font-size: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  //opacity: .2;
  transition: opacity .3s ease-in-out;
  cursor: pointer;

  &.slick-disabled {
    visibility: hidden;
  }

  &:hover {
    opacity: 1;
  }
  

  &::before {
    @include breakpoint(xs) {
      width: 45px;
      height: 45px;
    }
    font-family: "Font Awesome 5 Pro";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-size: 13px;
    color: #fff;
    font-size: 15px;
    font-weight: 100;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(20px);
    background: rgba(232, 25, 41, 0.16);
    border: 3px solid #d3d3d331;
  }

  &.slick-prev {
    @include breakpoint(xs) {
      left: 10px;
    }
    left: 0;
    //background-image: linear-gradient(-271deg, #333 0%, rgba(0,0,0,0.00) 100%);
  
    &::before {
      content: "\f060";
    }
  }

  &.slick-next {
    @include breakpoint(xs) {
      right: 10px;
    }
    right: 0;

   // background-image: linear-gradient(271deg, #333 0%, rgba(0,0,0,0.00) 100%);

    &::before {
      
      content: "\f061";
    }
  }
}

.slick-dots {
  display: block;
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 13px 20px 13px;
  

  li {
    @include breakpoint(xs) {
      padding: 0 12px;
    }
    display: inline-block;
    padding: 0 14px;

    button {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.363), 0 0 0 2px #4b4b4b9e, inset 0 0 0 3px #ededed52;
      transition: box-shadow .3s ease-in-out;
      outline: 0;
      padding: 0;
      font-size: 0;
      border: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
      background-color: transparent;
      cursor: pointer;
      transform-origin: center center;
      transform: scale(1);
      opacity: 1;
    }

    &.slick-active {
      button {
        box-shadow: inset 0 0 0 1px white, 0 0 0 2px #4b4b4b9e, inset 0 0 0 3px #ededed52;
        transform: scale(1.6);
        opacity: 1;
        
      }
    }
  }
}