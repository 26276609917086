// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
//$text-font-stack: 'Montserrat', sans-serif !default;
$text-font-stack: "Gilroy", sans-serif !default;

/// Copy text color
/// @type Color
$text-color: #424b4e !default;

/// Main brand color
/// @type Color
$brand-color: #e7173e !default;

/// Light grey
/// @type Color
$light: #fff !default;

/// Light grey
/// @type Color
$light-grey: #f7f7f7 !default;

/// Medium grey
/// @type Color
$mid-grey: #d8d8d8 !default;

/// Dark grey
/// @type Color
$dark-grey: #CDCDCD !default;

/// Dark
/// @type Color
$dark: rgb(0, 0, 0) !default;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "small": 320px,
  "medium": 768px,
  "large": 1024px,
) !default;
