@import "../../../stylesheets/abstracts/variables";
@import "../../../stylesheets/abstracts/mixins";

.MadeInUSA {
  cursor: pointer;

  path {
    transition: fill 1s ease-in-out;
  }

  // &:hover {
  //   .flag {
  //     fill: #002868;
  //   }

  //   .letter-u,
  //   .letter-s,
  //   .letter-a {
  //     fill: $brand-color;
  //   }
  // }

}
