.TypographyPage {
  padding: 30px;
  max-width: 1220px;
  margin: auto;

  .typo,
  .buttons {
    margin-bottom: 80px;
  }

  .buttons {
    p {
      margin-bottom: 25px;
    }
    &-inner  {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
